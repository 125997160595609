// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component5Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center safearea bg-black"}     > <img  id='bloc0_0' src="/profiles/ploy6/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "font-bold text-base text-white"}     > </div>
          <div id='bloc0_2'  className={ "w-20  relative h-12 "}     > <FontAwesomeIcon  id='bloc0_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 text-white absolute"}  />
            <FontAwesomeIcon  id='bloc0_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div><div id='bloc1'  className={ " flex w-full p-4 bg-white"}     > <img  id='bloc1_0x0' src="https://fs.appisyou.com/apps/cdesign/page_5_thumb.png" className={ "w-40  h-80 p-3   object-cover"}     alt="undefined" />
  <img  id='bloc1_0x1' src="https://fs.appisyou.com/apps/cdesign/263069551709641216693.jpg" className={ "w-40  h-80 p-3   object-cover"}     alt="undefined" />
  <img  id='bloc1_0x2' src="https://fs.appisyou.com/apps/cdesign/page_3_thumb1710342762498.png" className={ "w-40  h-80 p-3   object-cover"}     alt="undefined" /></div><div id='bloc2'  className={ "w-full h-full bg-zinc-300  p-6 flex md:flex-row flex-col justify-center items-start text-zinc-900 py-8 relative m-40"}     > <img  id='bloc2_0' src="null" className={ "w-full h-full absolute -z-50  opacity-50 object-fill    object-cover"}     alt="undefined" />
          <div id='bloc2_1'  className={ "flex justify-between z-50"}     > <div id='bloc2_1_0'  className={ "flex  flex-col w-1/2 justify-center  items-center"}     > <div id='bloc2_1_0_0'  className={ "flex justify-center items-center text-4xl font-bold text-left my-2 px-4"}     >{`FaciaTherapie`} </div>
              <div id='bloc2_1_0_1'  className={ "flex justify-center  items-center text-4xl font-bold  my-2 px-4"}     >{`un soin innovant pour soigner`} </div></div>
            <div id='bloc2_1_1'  className={ "flex flex-col justify-start items-start w-1/2"}     > <div id='bloc2_1_1_0'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`L’anxiété, le stress ou la fatigue`} </div>
              <div id='bloc2_1_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les douleurs chroniques telles que les lombalgies et les cervicalgies, les rhumatismes, l’arthrose, la fibromyalgie `} </div>
              <div id='bloc2_1_1_2'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les lumbagos, les tendinites ou les torticolis `} </div>
              <div id='bloc2_1_1_3'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les troubles du transit `} </div>
              <div id='bloc2_1_1_4'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les céphalées, migraines et acouphènes`} </div>
              <div id='bloc2_1_1_5x0'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc2_1_1_5x1'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc2_1_1_5x2'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div></div></div></div><div id='bloc3'  className={ "w-full p-2"}     > <div id='bloc3_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc3_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc3_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc3_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc3_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc3_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc4'  className={ "w-full p-2"}     > <div id='bloc4_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc4_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc4_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc4_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc4_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc4_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc5'  className={ "w-full p-2"}     > <div id='bloc5_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc5_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc5_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc5_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc5_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc5_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc6'  className={ "w-full p-2"}     > <div id='bloc6_0'  className={ "w-full flex flex-col p-8 border-zinc-400 border-2 rounded-xl shadow-xl my-2 border-secondary_color text-secondary_color"}     > <div id='bloc6_0_0'  className={ "flex flex-row w-full items-center justify-center"}     > <FontAwesomeIcon  id='bloc6_0_0_0'  icon={ fa.faBoreHole}      className={ "p-2 w-6 h-6"}  />
              <div id='bloc6_0_0_1'  className={ "text-xl font-bold p-2 grow text-left"}     > </div>
              <FontAwesomeIcon  id='bloc6_0_0_2'  icon={ fa.faAngleDown}      className={ "text-2xl opacity-50 duration-400"}  /></div>
            <div id='bloc6_0_1'  className={ "text-left duration-300 overflow-hidden w-full"}     > </div></div></div><div id='bloc7'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc7_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc7_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_0_0'  className={ "text-xl font-bold"}     >{`Communauté`} </div>
              <div id='bloc7_0_0_1'       >{`Réseaux sociaux`} </div>
              <div id='bloc7_0_0_2'       >{`Parrainnage`} </div>
              <div id='bloc7_0_0_3'       >{`Forums privés`} </div></div>
            <div id='bloc7_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_1_0'  className={ "font-bold text-xl"}     >{`Solutions`} </div>
              <div id='bloc7_0_1_1'       >{`Communication`} </div>
              <div id='bloc7_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc7_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc7_0_1_4'       > </div></div>
            <div id='bloc7_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_2_0'  className={ "font-bold text-xl"}     >{`Mentions légales`} </div>
              <div id='bloc7_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc7_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc7_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc7_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc7_0_3_0'  className={ "text-3xl font-bold"}     >{`chris design`} </div>
              <div id='bloc7_0_3_1'  className={ "text-xl"}     >{`L'app c'est vous!`} </div>
              <div id='bloc7_0_3_2'  className={ "underline font-bold"}     >{`A propos`} </div>
              <div id='bloc7_0_3_3'  className={ "underline font-bold"}     >{`Contact`} </div></div></div></div></>
  
}

export default Component5Page;
