// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component68Page() {
//useNavigate const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
useEffect(()=>{  action_event_447_bloc3()   },[])

const action_447 =   async ({TemplateConsent,setTemplateConsent})=> {
        try { 
        window.alert("TEST OK")
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_447_bloc3 =  async(event)=>{

                            
                           
              
                          await action_447( { event }) 
              
                          };  

const action_453 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(!Etat)
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc3_1_1 =(value)=> setInternal("etat1",value);

const action_event_453_bloc3_1_1 =  async(event)=>{

                            
                           var Etat = content["etat1"];
              
                          await action_453( { Etat,event, setEtat:setEtatbloc3_1_1  }) 
              
                          };  

const action_454 =   async ({Etat,setEtat})=> {
        try { 
        setEtat(Etat!=="vert" ? "vert":"rose")
}
        catch (error) {
        console.error(error);
        }
      };  

const setEtatbloc3_1_2 =(value)=> setInternal("etat2",value);

const action_event_454_bloc3_1_2 =  async(event)=>{

                            
                           var Etat = content["etat2"];
              
                          await action_454( { Etat,event, setEtat:setEtatbloc3_1_2  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-col"}     > <div id='bloc0_0'  className={ "w-full   p-4 text-center bg-secondary_color text-white text-lg letter-spacing=0.2rem"}   style = { {"letter-spacing":"0.2rem"}}   >{`100% BIOLOGIQUE . 100% LOCAL . 100% DE SAISON . LIVRAISON GRATUITE .`} </div>
          <div id='bloc0_1'  className={ "w-full flex flex-row justify-between items-center text-black  z-10 backgroundColor=#F8F8F8 pl-4"}   style = { {"backgroundColor":"#F8F8F8"}}   > <img  id='bloc0_1_0' src="https://fs.appisyou.com/apps/konjaque/Logo Konjaquea1709905421504.png" className={ "w-10 h-10 rounded-xl m-2    object-cover"}     alt="undefined" />
            <div id='bloc0_1_1'  className={ " text-base grow text-left uppercase   letter-spacing=0.3rem font-size=25px"}   style = { {"letter-spacing":"0.3rem","font-size":"25px"}}   >{`chris design`} </div>
            <div id='bloc0_1_2'  className={ "w-20  relative h-12"}     > <FontAwesomeIcon  id='bloc0_1_2_0'  icon={ fa.faArrowRightToBracket}      className={ "p-4 absolute"}  />
              <FontAwesomeIcon  id='bloc0_1_2_1'  icon={ fa.faHomeLgAlt}      className={ "p-4 text-white absolute"}  /></div></div></div><div id='bloc1'  className={ "w-full py-4 grid  md:grid-cols-2   grid-cols-1 gap-4 backgroundColor=#F8F8F8 "}   style = { {"backgroundColor":"#F8F8F8"}}   > <div id='bloc1_0'  className={ "flex flex-row w-full justify-center md:justify-end  mt-10"}     > <img  id='bloc1_0_0' src="https://fs.appisyou.com/apps/konjaque/panier-removebg-preview1708331093664.png" className={ " w-full  md:w-3/4  h-50    object-cover"}     alt="undefined" /></div>
          <div id='bloc1_1'  className={ "flex flex-col p-4 py-10 justify-center  items-center  md:justify-center md:items-start "}     > <div id='bloc1_1_0'  className={ "font-size=3rem  py-2"}   style = { {"font-size":"3rem"}}   >{`VOTRE PANIER`} </div>
            <div id='bloc1_1_1'  className={ " flex md:flex-row flex-col justify-center items-center py-4"}     > <div id='bloc1_1_1_0'  className={ "font-size=6rem line-height=6rem"}   style = { {"font-size":"6rem","line-height":"6rem"}}   >{`100%`} </div>
              <div id='bloc1_1_1_1'  className={ "flex md:flex-col text-xs justify-start items-start px-3 font-size=1.5rem line-height=1.5rem"}   style = { {"font-size":"1.5rem","line-height":"1.5rem"}}   > <div id='bloc1_1_1_1_0'       >{`BIO.`} </div>
                <div id='bloc1_1_1_1_1'       >{`LOCAL.`} </div>
                <div id='bloc1_1_1_1_2'       >{`DE SAISON`} </div></div></div>
            <div id='bloc1_1_2'       > </div>
            <div id='bloc1_1_3'  className={ "font-size=3rem    pt-2 pb-6"}   style = { {"font-size":"3rem"}}   >{`CHAQUE SEMAINE`} </div>
            <div id='bloc1_1_4'  className={ "shadow bg-orange-400 bg-primary_color p-2 flex flex-row text-white justify-center items-center font-size=2rem px-6 letter-spacing=0.4vw bottom=-1.5rem"}   style = { {"font-size":"2rem","letter-spacing":"0.4vw","bottom":"-1.5rem"}}   > <div id='bloc1_1_4_0'       >{`s'abonner`} </div>
              <FontAwesomeIcon  id='bloc1_1_4_1'  icon={ fa.faArrowRight}      className={ "p-1"}  /></div></div></div><div id='bloc2'  className={ " h-screen relative w-screen"}     > <map id='bloc2_0'  className={ "w-full h-full"}     > </map>
          <div id='bloc2_1'  className={ "safearea-bottom absolute   left-0 w-full  p-2   z-10 flex flex-row items-center justify-center bottom=5vh"}   style = { {"bottom":"5vh"}}   > <div id='bloc2_1_0'  className={ "w-full md:w-96"}     > <div id='bloc2_1_0_0'  className={ "p-4 flex flex-row w-full"}     > <FontAwesomeIcon  id='bloc2_1_0_0_0'  icon={ fa.faMagnifyingGlass}      className={ "text-zinc-800 -mr-10 z-10 py-2 px-4"}  />
                <div id='bloc2_1_0_0_1'  className={ " w-full bg-zinc-100 h-8 rounded-xl pl-8 flex flex-row items-center shadow"}     > <div id='bloc2_1_0_0_1_0'  className={ "text-left text-zinc-900"}     >{`Rechercher`} </div></div>
                <FontAwesomeIcon  id='bloc2_1_0_0_2'  icon={ fa.faMicrophone}      className={ "px-4 p-2 text-zinc-800 -ml-10"}  /></div></div></div></div><div id='bloc3'  className={ "w-full flex flex-col justify-center items-center w-full flex flex-col justify-center items-center py-4 safearea-top"}    onStart = { action_event_447_bloc3} > <div id='bloc3_0'  className={ "h-40  w-full bg-orange-400  bg-primary_color"}     > </div>
          <div id='bloc3_1'  className={ "   -mt-32 -mb-32    md:-my-20 z-10 flex flex-col items-center justify-center md:flex-row"}     > <div id='bloc3_1_0'  className={ "bg-white  w-40 h-40 z-10 rounded-xl p-2"}     > <img  id='bloc3_1_0_0' src="undefined" className={ "w-full h-full rounded-xl   object-cover"}     alt="undefined" /></div>
            <div id='bloc3_1_1'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_453_bloc3_1_1} > <FontAwesomeIcon  id='bloc3_1_1_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc3_1_1_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc3_1_1_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc3_1_1_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div>
            <div id='bloc3_1_2'  className={ " py-2 bg-zinc-900 flex flex-row items-center rounded-lg border-white"}    onClick = { action_event_454_bloc3_1_2} > <FontAwesomeIcon  id='bloc3_1_2_0'  icon={ fa.faDownload}      className={ "w-8 h-8 text-white"}  />
              <div id='bloc3_1_2_1'  className={ "px-1 flex flex-col justify-center items-center  "}     > <div id='bloc3_1_2_1_0'  className={ "text-white text-xs"}     >{`Install with`} </div>
                <div id='bloc3_1_2_1_1'  className={ "text-white text-xl font-extrabold"}     >{`AppIsYou`} </div></div></div></div>
          <div id='bloc3_2'  className={ "h-40  w-full bg-blue-200 bg-secondary_color"}     > </div></div><div id='bloc4'  className={ "flex flex-col justify-start items-center p-4 w-full"}     > <div id='bloc4_0'       > <FontAwesomeIcon  id='bloc4_0_0'  icon={ fa.faDesktopAlt}      className={ "text-3xl m-1 text-secondary_color"}  />
            <FontAwesomeIcon  id='bloc4_0_1'  icon={ fa.faMobileAndroidAlt}      className={ "text-3xl m-1 text-secondary_color "}  /></div>
          <div id='bloc4_1'  className={ "font-bold"}     >{`Télécharger l'application KONJAQUE !`} </div>
          <div id='bloc4_2'  className={ "m-1 "}     >{`Gérer votre abonnement, vos horaires de livraison; participez à la communauté, informez-vous avec la newsletter. `} </div></div><div id='bloc5'  className={ "w-full bg-black bg-primary_color flex flex-row justify-center"}     > <div id='bloc5_0'  className={ "    p-2 h  grid  grids-cols-1  md:grid-cols-2  lg:grids-cols-3  xl:grid-cols-4 gap-1 text-white"}     > <div id='bloc5_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc5_0_0_0'  className={ "text-xl font-bold"}     >{`A propos`} </div>
              <div id='bloc5_0_0_1'       >{`Nos valeurs`} </div>
              <div id='bloc5_0_0_2'       >{`Nos missions`} </div>
              <div id='bloc5_0_0_3'       >{`Parrainnage`} </div></div>
            <div id='bloc5_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc5_0_1_0'  className={ "font-bold text-xl"}     >{`Contact`} </div>
              <div id='bloc5_0_1_1'       >{`Communication`} </div>
              <div id='bloc5_0_1_2'       >{`Gestion clientèle`} </div>
              <div id='bloc5_0_1_3'       >{`Boutique en ligne`} </div>
              <div id='bloc5_0_1_4'       > </div></div>
            <div id='bloc5_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc5_0_2_0'  className={ "font-bold text-xl"}     >{`Légal`} </div>
              <div id='bloc5_0_2_1'       >{`Conditions générales d'utilisation`} </div>
              <div id='bloc5_0_2_2'       >{`Conditions générales de vente`} </div>
              <div id='bloc5_0_2_3'       >{`Politique de confidentialité`} </div></div>
            <div id='bloc5_0_3'  className={ " flex flex-col items-start p-4"}     > <div id='bloc5_0_3_0'  className={ "text-3xl font-bold"}     > </div>
              <div id='bloc5_0_3_1'  className={ "text-base italic"}     >{`Le bio dans son panier !`} </div>
              <div id='bloc5_0_3_2'  className={ "underline font-bold"}     >{`@2024 Konjaque`} </div></div></div></div></>
  
}

export default Component68Page;
